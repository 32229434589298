import { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import styled from 'styled-components'

import Card from './Card'

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}

const Section = (props) => {
  const [width] = useWindowSize()
  const [index, setIndex] = useState(0)
  const [size, setSize] = useState(5)
  const [elem, setElem] = useState(undefined)

  const { id, title, list = [] } = props
  const w = elem ? elem.clientWidth : 220
  useEffect(() => {
    const n = Math.trunc(w / 220)
    setSize(n)
  }, [w, width])

  const onRef = useCallback((node) => {
    if (node) setElem(node)
  }, [])

  const len = list.length
  if (len === 0) {
    return <div />
  }

  const onBack = () => {
    if (index > 0) setIndex(index - 1)
  }

  const onNext = () => {
    const max = Math.ceil(len / size) - 1
    if (index < max) setIndex(index + 1)
  }

  const i = index * size
  const content = list.slice(i, i + size).map((it, i) => {
    return (
      <Card key={i} item={it} isFirst={i === 0} />
    )
  })

  const maxWidth = size === 1 ? undefined : `${size * 220}px`
  return (
    <PageView ref={onRef}>
      <Title>{title}</Title>
      <Content id={id} style={{ maxWidth }}>
        <ContentLine>
          {content}
        </ContentLine>

        <PreviousBtn className="fas fa-angle-left" onClick={onBack} />
        <NextBtn className="fas fa-angle-right" onClick={onNext} />
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Title = styled.div`
  color: #333333;
  font-weight: 700;
  font-size: 20px;
`

const ContentLine = styled.div`
  display: flex;
`

const Content = styled.div`
  padding: 24px 0px;
  position: relative;
`

const IconBtn = styled.i`
  font-size: 28px;
  position: absolute;
  top: 38%;
  z-index: 5;
  cursor: pointer;
  padding: 12px 8px;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 6px;
`

const PreviousBtn = styled(IconBtn)`
  left: 10px;
`

const NextBtn = styled(IconBtn)`
  right: 10px;
`

export default Section
