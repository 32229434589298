import { Component } from 'react'
import styled from 'styled-components'
import { Layout, Row, Col } from 'antd'

import { config } from '../config'
import { color } from '../definition'

import Main from './main'
import Social from './social'
import Contact from './contact'
import Policy from './policy'

export default class Footer extends Component {
  render() {
    return (
      <FootBar>
        <Layout.Footer>
          <PageView>
            <Row type="flex">
              <Col { ...main }>
                <Main />
              </Col>
              <Col { ...normal }>
                <Contact />
              </Col>

              <Col { ...normal }>
                <Policy />
              </Col>

              <Col { ...normal }>
                <Social />
              </Col>
            </Row>

            <Company>
              Copyright 2022 <i className="far fa-copyright" /> บริษัท ประธานการไฟฟ้า จำกัด
              <Version>
                version { config.version }
              </Version>
            </Company>
          </PageView>
        </Layout.Footer>
      </FootBar>
    )
  }
}

const PageView = styled.div`
  width: 1440px;
  margin: 0 auto;

  @media screen and (max-width: 1440px) {
    width: 100%;
    padding: 0px 30px;
  }

  @media screen and (max-width: 1024px) {
    padding: 0px 20px;
  }
`
const Company = styled.div`
  color: #F0F0F0;
  font-size: 12px;
  margin-top: 15px;

  @media screen and (max-width: 500px) {
    text-align: center;
  }
`

const Version = styled.span`
  color: #F0F0F0;
  font-size: 10px;
  float: right;
`

const FootBar = styled.div`
  width: 100%;
  top: 0px;
  z-index: 20;

  .ant-layout-footer {
    background-color: ${ color.bg };
    padding: 20px 5px;
    color: ${ color.font };
    width: 100%;
    min-height: 200px;
  }

  @media print {
    display: none !important;
  }
`

const main = {
  lg: { span: 9 },
  md: { span: 24 },
  sm: { span: 24 },
  xs: { span: 24 },
}

const normal = {
  lg: { span: 5 },
  md: { span: 8 },
  sm: { span: 24 },
  xs: { span: 24 },
}
