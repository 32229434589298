
const color = {
  bg: '#00734C',
  font: 'white',
  border: '#d3d6db',
  remove: '#FB8B8B'
}

export {
  color,
}
